<template>
  <div class="submenu flex-row justify-center">
    <a-layout class="level2Main flex-row justify-center">
      <div class="Menu flex-row justify-center">
        <!-- 左侧菜单栏 -->
        <div>
          <a-layout-sider class="leftMenu">
            <a-menu mode="inline" v-model:selectedKeys="selectedKeys">
              <a-menu-item v-for="item in menu" :key="item.id">
                <router-link replace :to="item.path">{{
                  item.label
                }}</router-link>
              </a-menu-item>
            </a-menu>
          </a-layout-sider>
        </div>

        <!-- 中部 -->
        <a-layout class="view">
          <!-- 页面 -->
          <router-view />
        </a-layout>
      </div>
    </a-layout>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { permissionReadJurisdiction } from '@/assets/common.js'
export default defineComponent({
  data () {
    return {
      menu: [
        {
          id: 1,
          path: '/EnterpriseManagement/UseringDetail',
          label: '使用详情',
        }, {
          id: 2,
          path: '/EnterpriseManagement/BasicInformation',
          label: '企业信息',
        }, {
          id: 3,
          path: '/EnterpriseManagement/Application',
          label: '应用管理',
        }, {
          id: 4,
          path: '/EnterpriseManagement/OrderInfo',
          label: '订单信息',
        }, {
          id: 5,
          path: '/EnterpriseManagement/ApplyJoin',
          label: '申请记录',
        }, {
          id: 6,
          path: '/EnterpriseManagement/MessageLog',
          label: '消息推送日志',
        },
      ],
      selectedKeys: [], // 当前选中的页面
    }
  },
  watch: {
    $route (to) {
      this.selectKey(to)
    },
  },
  created () {
    // console.log(permissionReadJurisdiction())
    if (permissionReadJurisdiction()) {
      const obj = {
        id: 7,
        path: '/EnterpriseManagement/Permission',
        label: '平台权限',
      }
      this.menu.push(obj)
    }
    const path = this.$route
    // console.log(path)
    this.selectKey(path)
  },
  methods: {
    selectKey (path) {
      const item = this.menu.find(function (item) {
        if (path.path === '/EnterpriseManagement/Payment' && path.query.payment !== undefined) {
          if (path.path === '/EnterpriseManagement/Payment') {
            if (item.id === 1) {
              item.path = path.path
            }
          }
        }
        if (path.path === '/EnterpriseManagement/ProductAttribute' && path.query !== {}) {
          if (path.path === '/EnterpriseManagement/ProductAttribute') {
            if (item.id === 1) {
              item.path = path.path
            }
          }
        }
        if (path.path === '/EnterpriseManagement/PaymentSuccessFul') {
          if (item.id === 1) {
            item.path = path.path
          }
        }
        return item.path === path.path
      })
      if (item) {
        if (path.path === '/EnterpriseManagement/Payment' && path.query !== {}) {
          item.path = '/EnterpriseManagement/UseringDetail'
        }
        if (path.path === '/EnterpriseManagement/ProductAttribute' && path.query !== {}) {
          item.path = '/EnterpriseManagement/UseringDetail'
        }
        const selectedKeys = []
        selectedKeys.push(item.id)
        this.selectedKeys = selectedKeys
      }
    },
  },
})

</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
.submenu {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  margin: 0 auto;
}
.level2Main {
  margin-top: 24px;
  width: 82%;
  // background-color: pink;
}
.Menu {
  width: 82%;
}
.leftMenu {
  width: 14%;
  min-height: 500px;
  background: #fff;
  position: fixed;
}
::v-deep(.ant-menu-item) {
  margin-top: 0;
}
::v-deep(.ant-layout-sider-children) {
  min-height: 500px;
}
.view {
  margin-left: 210px;
  width: 80%;
  // margin-left: 211px;
}
</style>
